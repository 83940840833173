export function makeListRoute() {
  const userInfoStore = useUserStore()
  const filterConditionStore = useFilterConditionsStore()
  const countryCode = filterConditionStore.filterConditions.selectedCity?.countryCode || 'x'
  const cityName = (filterConditionStore.filterConditions.selectedCity?.destinationName || 'y')
    .replace(/\s/g, '-')
    .toLowerCase()
  const locale = userInfoStore.userInfo.locale

  return `${countryCode}/${cityName}/${locale}.html`
}

export function makeDetailRoute(hotelName: string) {
  hotelName = hotelName.replace(/\s/g, '-').toLowerCase()
  const userInfoStore = useUserStore()
  const filterConditionStore = useFilterConditionsStore()
  const countryCode = filterConditionStore.filterConditions.selectedCity?.countryCode || 'x'
  const cityName = (filterConditionStore.filterConditions.selectedCity?.destinationName || 'y')
    .replace(/\s/g, '-')
    .toLowerCase()
  const locale = userInfoStore.userInfo.locale

  return `${countryCode}/${cityName}/${hotelName}.${locale}.html`
}
